<template>	
	<el-container class="home_conter">
		<!-- 顶部菜单 -->
	  <el-header>
		  <div class="header_logo">
		  	<!-- <img src="../assets/logo.png" width="80" height="60"> -->
			<span>店易通收银后台管理系统</span>
		  </div>
		  <div>
			  <el-dropdown @command="handleCommand">
			    <span class="el-dropdown-link">
			      欢迎回来：{{username}}<i class="el-icon-arrow-down el-icon--right"></i>
			    </span>
			    <el-dropdown-menu slot="dropdown">
					<!-- <el-dropdown-item command="1">设置</el-dropdown-item> -->
			      <el-dropdown-item command="outLogin">登出</el-dropdown-item>
				  
			    </el-dropdown-menu>
			  </el-dropdown>
		  </div>
	  </el-header>
	  <el-container>
		<el-aside width="200px">
			<el-menu router>
			  <!-- 一级导航 -->
			  <el-submenu class="Aside_Conter" :index='item.index' 
			  v-for="(item,index) in this.$router.options.routes" :key="index" v-if="!item.hidden && item.GRADE == GRADE || item.GRADE == 0">
				<template slot="title"> 
				  <span>{{item.name}}</span>
				</template>
				<!-- 二级导航 -->
				<el-menu-item :index="children.path" v-for="(children,indexj) in item.children" :key="indexj" v-if="!children.hidden">
					<span slot="title">{{children.name}}</span>
				</el-menu-item>
			  </el-submenu>
			</el-menu>
		</el-aside>
		<el-main>
			<router-view></router-view>
		</el-main>
	  </el-container>
	</el-container>
</template>

<script>
	export default {
		data(){
			return{
				memuList:[],
				activePath:'',
				username:'',
				GRADE:0
			}
		},
		methods:{
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			  },
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			handleCommand(command) {
				if (command == 'outLogin'){
					window.sessionStorage.clear()
					window.localStorage.clear()
					this.$router.push('/');
				}
			  }
		},
		created() {
			// this.getMemuList()
			let getUserSession = JSON.parse(sessionStorage.getItem('userSession'));
			this.activePath = window.localStorage.getItem('path');
			this.username = getUserSession.username
			this.GRADE = getUserSession.GRADE
		}
	}
</script>

<style scoped>
	.home_conter{
		height: 100%;
	}
	.header_logo{font-family: "微软雅黑";color: #ffd04b;}
	.Aside_Conter{
		width: 200px
	}
	.aside_show{
		color: #fff;background: rgb(74,80,100);cursor: pointer;
		text-align: center;
	}
	.el-header{
		background: rgb(55,61,65);
		display: flex;
		justify-content: space-between;
		line-height: 60px;
		color: #fff;
		font-size: 22px;
	}
	.el-header img{
		vertical-align: middle;
	}
	.el-aside{
		background: rgb(223, 223, 223);
	}
	.el-main{
		background: rgb(248, 252, 255);
	}
	.el-dropdown-link {
	    font-family: "微软雅黑";color: #ffd04b;
	  }
</style>
